.NavBar {
	display: flex;
	justify-content: space-between;
	background-color: #333;
	color: white;
	align-items: strech;
	gap: 2rem;
	padding: 0 1rem;
}

.NavBar__SiteTitle {
	font-size: 2rem
}

.NavBar ul {
	padding: 0;
	margin: 0;
	list-style: none;
	display: flex;
	gap: 1rem;
}

.NavBar a {
	color: inherit;
	text-decoration: none;
	height: 100%;
	display: flex;
	align-items: center;
	padding: .25rem;
}

.NavBar li.active {
	background-color: #555;
}

.NavBar li:hover {
	background-color: #777;
}